<template>
  <div class="detail-page">
    <Header />
    <Chartbar v-if="show" :data="chartData" />
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import {fetchCustomSalesAnalysis} from "@/api/home";
import Chartbar from './chartbar';

export default {
  components: {
    Header,
    Chartbar,
  },
  data() {
    return {
      show: false,
      chartData: {},
    }
  },
  activated() {
    this.getChartData();
  },
  methods: {
    async getChartData() {
      //扇形图数据
      // const res = await fetchChartData();
      //柱状图数据
      const res = await fetchCustomSalesAnalysis();
      this.chartData = res.data;
      this.show = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-page {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;

}
</style>

