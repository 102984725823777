<template>
  <div class="chart-container">
    <div id="myChart" class="chart"></div>
    <!-- <router-link to="/dataMonitor">
      <Button class="button">报表中心</Button>
    </router-link> -->
  </div>
</template>

<script>
// 引入基本模板
const echarts = require('echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')

// import Button from '../../../components/common/button/CustomButton';
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  // components: {
  //   Button,
  // },
  mounted() {
    this.drawLine();
  },
  
  methods: {
    drawLine(){
      if (!this.data) {
        return false;
      }
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('myChart'));
      var cRowData = [];
      var rowData = [];
      Object.values(this.data.monthData).forEach((value,index) => {
        if (index < 12) {  //去年数据
          cRowData.push(value);
        } else{ //今年数据
          rowData.push(value);
        }
      });
      // 绘制图表
      myChart.setOption({
       /* title: { 
          subtext: '销量统计',
          textStyle: {
            fontSize: '12px',
          },
          x: 'center'
        },*/
        legend: {
          right : '10%',
          top : '5%',
          data: [this.data.year,this.data.cYear]
        },
        tooltip:{
          trigger:'axis'
        },
        xAxis: {
          type: "category",
          data: ["1月", "2月", "3月", "4月", "5月",'6月','7月',"8月",'9月','10月',"11月", "12月", ],
          axisLabel : {
            fontSize : 8
          }
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: this.data.year,
            data: rowData,
            type: "bar",
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: '#E5A23C',
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: 'black',
                    fontSize: 6
                  }
                }
              }
            }
          },
          {
            name: this.data.cYear,
            data: cRowData,
            type: "bar",
            barWidth: "10px",
            itemStyle: {
              normal: {
                color: '#F56C6C',
                label: {
                  show: true, //开启显示
                  position: 'top', //在上方显示
                  textStyle: { //数值样式
                    color: 'black',
                    fontSize: 6
                  }
                }
              }
            }
          },
        ]
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 300px;
  &-container {

    .button {
      width: 200px;
    }
  }
}
</style>